import Vue from "vue"
import Vuex from "vuex"
import router from "../router"
import axios from "axios"

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		authUser: {
			email: "",
		},
		tempAuthUser: {
			email: "",
		},
		activeTab: "",
		detailActiveTab: "",
		calenderFilterIds: [],
	},
	mutations: {
		authUser(state, userData) {
			localStorage.setItem("authUser", JSON.stringify(userData))
			state.authUser = userData
		},
		tempAuthUser(state, userData) {
			localStorage.setItem("tempAuthUser", JSON.stringify(userData))
			state.tempAuthUser = userData
		},
		setRegisterData(state, data) {
			state.registerData = data
		},
		setActiveTab(state, data) {
			localStorage.setItem("activeTab", data)
			state.activeTab = data
		},
		setDetailActiveTab(state, data) {
			localStorage.setItem("detailActiveTab", data)
			state.detailActiveTab = data
		},
		setCalenderFilterIds(state, data) {
			localStorage.setItem("calenderFilterIds", JSON.stringify(data))
			state.calenderFilterIds = data
		},
	},
	actions: {
		async signUp({ commit }, signUpData) {
			const response = await axios.post(`${process.env.VUE_APP_API_URL}/users`, {
				user: signUpData.user,
				"g-recaptcha-response-data": signUpData.recaptchaToken,
			})
			if (response.data.errors !== undefined) {
				Vue.toasted.show(response.data.errors, {
					type: "error",
					duration: 2000,
				})
				return
			}
			return response
		},
		async logIn({ commit, getters }, { authData, haltRedirect, legacyRedirectUrl }) {
			const response = await axios.post(
				`${process.env.VUE_APP_API_URL}/sign_in`,
				{
					session: { ...authData },
				},
				{ withCredentials: true },
			)
			if (response.data.errors !== undefined) {
				Vue.toasted.show(response.data.errors, {
					type: "error",
					duration: 2000,
				})
				return
			}
			commit("authUser", response.data)
			commit("tempAuthUser", { email: "" })
			Vue.toasted.success("You have successfully logged in").goAway(3000)
			const redirect = localStorage.getItem("redirect")
			if (redirect?.includes("http")) {
				location.href = redirect
			} else if (!haltRedirect) {
				if (redirect !== null && redirect !== "") {
					router.replace(redirect)
					localStorage.setItem("redirect", "")
				} else {
					router.replace("/")
				}
			}
			return response
		},
		async signInAs({ commit, getters }, { userId, adminUserId }) {
			const response = await axios.post(`${process.env.VUE_APP_API_URL}/users/user_login_visit`, {
				user_id: userId,
				admin_user_id: adminUserId,
			})
			if (response.data.errors !== undefined) {
				Vue.toasted.show("Something went wrong. Sign In is failed", {
					type: "error",
					duration: 2000,
				})
				return
			}
			commit("authUser", response.data)
			Vue.toasted.success(`You have successfully logged in as ${response.data.name}`).goAway(5000)
			router.replace("/")
		},
		thirdPartyLogin({ commit, getters }, { authData }) {
			axios
				.get(`${process.env.VUE_APP_API_URL}/logged_in_user_data`, {
					params: {
						email: authData.email,
						id: authData.id,
					},
				})
				.then((res) => {
					if (res.data.errors !== undefined) {
						Vue.toasted.show("Something went wrong. Sign In is failed", {
							type: "error",
							duration: 2000,
						})
						return
					}
					commit("authUser", res.data)
					commit("tempAuthUser", { email: "" })
					Vue.toasted.success("You have successfully logged in").goAway(5000)
					const redrtUrl = localStorage.getItem("redirect")
					if (redrtUrl !== "" && redrtUrl !== null) {
						localStorage.setItem("redirect", "")
						location.href = redrtUrl
					} else {
						router.replace("/")
					}
				})
		},
		resetLogin({ commit }) {
			commit("authUser", { email: "" })
			commit("tempAuthUser", { email: "" })
			commit("setCalenderFilterIds", [])
			localStorage.setItem("LS_ROUTE_KEY", "")
			localStorage.setItem("isOrcidSignUpFromRegistrationPage", "")
			document.cookie = "viewAs=false;domain=.slmath.org;path=/;"
		},
		async logOut({ dispatch }, data) {
			const response = await axios.delete(`${process.env.VUE_APP_API_URL}/sign_out`, { withCredentials: true })
			dispatch("resetLogin")
			if (data) router.replace({ name: "home" })
			return response
		},
		updateCurrentTab({ commit }, data) {
			commit("setActiveTab", data)
		},
		updateDetailCurrentTab({ commit }, data) {
			commit("setDetailActiveTab", data)
		},
		updateTempAuthData({ commit }, data) {
			commit("tempAuthUser", data)
		},
		updateCalenderFilterIds({ commit }, data) {
			commit("setCalenderFilterIds", data)
		},
	},
	getters: {
		authUser: (state) => {
			if (typeof state.authUser.email !== "undefined" && state.authUser.email !== null && state.authUser.email !== "") {
				return state.authUser
			}
			const obj = localStorage.getItem("authUser")
			state.authUser = JSON.parse(obj)
			return state.authUser
		},
		tempAuthUser: (state) => {
			if (
				typeof state.tempAuthUser.email !== "undefined" &&
				state.tempAuthUser.email !== null &&
				state.tempAuthUser.email !== ""
			) {
				return state.tempAuthUser
			}
			const obj = localStorage.getItem("tempAuthUser")
			state.tempAuthUser = JSON.parse(obj)
			return state.tempAuthUser
		},
		isAuthenticated: (_, getters) => {
			return (
				getters.authUser !== null &&
				getters.authUser.email !== null &&
				getters.authUser.email !== "" &&
				getters.authUser.email !== undefined
			)
		},
		getActiveTab: (state) => {
			const obj = localStorage.getItem("activeTab")
			state.activeTab = obj
			return state.activeTab
		},
		getDetailActiveTab: (state) => {
			const obj = localStorage.getItem("detailActiveTab")
			state.detailActiveTab = obj
			return state.detailActiveTab
		},
		canViewSac: (state) => {
			return state.authUser.can_view_sac
		},
		canViewHrac: (state) => {
			return state.authUser.can_view_hrac
		},
		canViewStaffDashboard: (state) => {
			return state.authUser.can_view_staff_dashboard
		},
		canEditCms: (state) => {
			return state.authUser ? state.authUser.can_cms_edit : false
		},
		canViewProgramAdminTool: (state) => {
			return state.authUser ? state.authUser.can_view_program_admin_tools : false
		},
		canEditVimeo: (state) => {
			return state.authUser ? state.authUser.can_vimeo_edit : false
		},
		getUserOrcid: (state) => {
			return state.authUser.person_orcid_id
		},
		getCalenderFilterIds: (state) => {
			if (localStorage.getItem("calenderFilterIds")) {
				const obj = localStorage.getItem("calenderFilterIds")
				state.calenderFilterIds = JSON.parse(obj)
				return state.calenderFilterIds
			}
			return []
		},
	},
})
